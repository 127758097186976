import { css } from '@linaria/core';
import { REM13, REM15, REM18, REM22, REM24, REM28, REM20, ROOT_FONT_SIZE, ROOT_MOBILE_FONT_SIZE, ROOT_LINE_HEIGHT } from 'src/theme/Types';
import { HairlineVarName, Colors, MainContentWidth, SideRailWidth, ChatListWidth, NavBarWidth, SmallMobileBreakpoint, FontWeights } from 'src/theme/constants';
import { followDeepLinks } from 'src/scopes/deeplinks/deeplinks';
import Head from 'next/head';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AuthTokensKey, JWTValues } from 'src/scopes/auth/constants';
import config from 'src/config';
import { useEffect } from 'react';
import { Env } from '../src/config/config.default';
import { Router, useRouter } from 'next/router';
import { DefaultSeo, SiteLinksSearchBoxJsonLd, LogoJsonLd, SocialProfileJsonLd } from 'next-seo';
import jwt_decode from 'jwt-decode';
import 'src/polyfills/requestIdleCallback';
import 'src/polyfills/allSettled.polyfill';
import 'src/scopes/analytics/google-analytics';
import { captureException } from 'src/dev/sentry';
import { ldAnonUser } from 'src/scopes/analytics/constants';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { identifyAnalytics, setAnalyticsLoggedOut } from 'src/scopes/analytics/mixpanel';
import { isServer } from 'src/utils/isServer';
import ScreenAnalytics from 'src/scopes/analytics/ScreenAnalytics';
import { OxcartPage } from 'src/utils/oxcart';
import { scrollbar } from 'src/theme/scrollbar';
const DEFAULT_DESCRIPTION = 'Commonstock is a social network that amplifies the knowledge of the best investors, verified by actual track records for signal over noise. Community members can link their existing brokerage accounts and share their real time portfolio, performance and trades (by percent only, dollar amounts never shared).';
type Props = {
  Component: OxcartPage<unknown>;
  pageProps: any;
  router: Router;
};
if (typeof window !== 'undefined' && typeof location !== 'undefined') followDeepLinks();

const MyApp = ({
  Component,
  pageProps,
  router
}: Props) => {
  const {
    getLayout
  } = Component;
  const origin = pageProps.origin ? pageProps.origin : config.originUrl; // reset visibility once the first render

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.style.visibility = 'unset';
    }
  }, []);
  let path = useRouter().asPath;
  let pathWithoutQuery = path.split('?')[0]; // ignore query for this, as in all current cases query doees not affect the url we want google to index

  const canonicalURL = origin + pathWithoutQuery;
  let head = <>
      <Head>
        <link rel="shortcut icon" href="/favicon.svg" />
        <title>Commonstock</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
        <link rel="canonical" href={canonicalURL} />

        {typeof window !== 'undefined' && !localStorage.getItem(AuthTokensKey) && config.CS_ENV === Env.Prod && // @NOTE GA snipped in google-analytics.js
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.publicGATrackingId}`} />}
      </Head>
      <DefaultSeo defaultTitle={'Commonstock: Verified Investing Knowledge'} titleTemplate={'%s | Commonstock'} description={DEFAULT_DESCRIPTION} canonical={canonicalURL} openGraph={{
      url: canonicalURL,
      type: 'website',
      description: DEFAULT_DESCRIPTION,
      site_name: 'Commonstock',
      locale: 'en_US'
    }} twitter={{
      handle: '@JoinCommonstock',
      site: '@JoinCommonstock',
      cardType: 'summary_large_image'
    }} facebook={{
      appId: '376652679391634'
    }} // @NOTE: Should we have an IOS only url for users on Google?
    // https://developers.google.com/search/mobile-sites/mobile-seo/other-devices
    // mobileAlternate={{
    //   media: 'only screen and (max-width: 640px)',
    //   href: 'https://m.canonical.ie',
    // }}
    />
      <LogoJsonLd logo={`${origin}/images/Group-1331.svg`} url={origin} />
      <SocialProfileJsonLd type="Organization" name="Commonstock" url={origin} sameAs={['https://twitter.com/joincommonstock', 'https://www.linkedin.com/company/commonstock']} />
      <SiteLinksSearchBoxJsonLd url={origin} potentialActions={[{
      target: `${origin}/search/{search_term_string}`,
      queryInput: 'search_term_string'
    } // @TODO: discuss with IOS to add in app search url: https://developers.google.com/search/docs/data-types/sitelinks-searchbox
    ]} />
    </>;
  return <ErrorBoundary>
      {head}
      <ScreenAnalytics Component={Component} router={router}>
        {getLayout ? getLayout(<Component {...pageProps} origin={origin} />) : <Component {...pageProps} origin={origin} />}
      </ScreenAnalytics>
    </ErrorBoundary>;
}; // attempt to decode jwt sync such that we can initialize launch darkly immediately


let tokenData: JWTValues | null = null;

try {
  let tokensString = typeof window !== 'undefined' && localStorage.getItem(AuthTokensKey);
  let tokens = tokensString ? JSON.parse(tokensString) : null;
  tokenData = tokens && jwt_decode(tokens.access);
} catch (err) {
  captureException(err, {
    context: 'JWT token decode in _app'
  });
}

if (tokenData?.uuid) identifyAnalytics(tokenData?.uuid);else if (!isServer) setAnalyticsLoggedOut(true); // set the provisional user using token data. The full user will be reconciled in AnalyticsControlleer.

let ldProvisionalUser = tokenData ? {
  key: tokenData?.uuid,
  email: tokenData?.email
} : ldAnonUser;
export default withLDProvider({
  clientSideID: config.launchDarklyClientId,
  user: ldProvisionalUser,
  options: {
    bootstrap: 'localStorage'
  }
})(MyApp); // linaria version (noop in development)

export const globals = "gpg0y66";

require("../.linaria-cache/packages/oxcart/pages/_app.linaria.css");